<template>
    <div class="tool-main-container">
        <div class="tool-main-item" :class="{'tool-main-item-active':currentMenusIndex==index}" v-for="(menu,index) in mainMenus" :key="index" @click="onMenuClickHandle(index)">
            <i class="iconfont" :class="[menu.icon]"></i>
            <span>{{menu.name}}</span>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        // ...mapGetters("webappEditModule", ["currentIsBaseMainMenu"]),
        ...mapState("webappEditModule", ["mainMenus", "currentMenusIndex"]),
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("webappEditModule", ["onMainMenuClickHandle"]),
        onMenuClickHandle(index) {
            this.onMainMenuClickHandle(index);
        },
    },
};
</script>
<style lang="scss" scoped>
.tool-main-container {
    height: 90px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .tool-main-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #999999;
        .iconfont {
            margin-bottom: 5px;
            font-size: 24px;
        }
    }
    .tool-main-item-active {
        color: $pano-font-color;
    }
}
</style>