<template>
    <div class="tool-setting-fov-container">
       <span class="tool-setting-fov-tip">选定进入场景时看到的初始视角</span>
       <div class="tool-setting-fov-button-container">
           设为初始视角
       </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .tool-setting-fov-container{
        height: 100%;
        background: $pano-framework-tool-background;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #9ca1b0;
        padding: 10px;
        box-sizing: border-box;
        .tool-setting-fov-tip{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .tool-setting-fov-button-container{
            margin-top: 10px;
            border-radius: 15px;
            height: 32px;
            background: $pano-theme;
            color: $pano-font-color;
            padding: 0 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>