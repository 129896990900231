<template>
    <div class="tool-setting-hotspot-container">
        <div class="hotspot-left-container">
            <div class="hotspot-left-inner-container" v-if="currentScene">
                <div class="hotspot-icon-item-container" :class="{'hotspot-icon-item-container-active':item.id==currentHotspotId}" v-for="item in currentScene.hotspots" :key="item.id" @click="onHotspotSelectHandle(item)">
                    <img class="hotspot-icon-image" :src="item.iconThumb" alt="">
                    <span class="ellipsis">{{item.name}}</span>
                </div>
            </div>
        </div>
        <div class="hotspot-right-container" @click="onAddHotspotHandle">
            <i class="iconfont icon-hao"></i>
            <span>添加热点</span>
        </div>
    </div>
</template>
<script>
import HotspotAdd from "../../nav-bottom-slide/components/hotspot-add";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    computed: {
        ...mapState("webappEditModule", ["currentHotspotId"]),
        ...mapGetters("webappEditModule", ["currentScene"]),
    },
    data() {
        return {
            canSelect: true,
        };
    },
    methods: {
        ...mapActions("webappEditModule", [
            "onHotspotToggle",
            "onSlideClickHandle",
            "lookToHotspot",
        ]),
        onHotspotSelectHandle(hotspot) {
            if (this.canSelect) {
                this.canSelect = false;
                setTimeout(() => {
                    this.canSelect = true;
                }, 1000);
                this.onHotspotToggle(hotspot.id);
                if (this.currentHotspotId) {
                    this.lookToHotspot(this.currentHotspotId);
                }
            }
        },
        onAddHotspotHandle() {
            this.onSlideClickHandle({
                openType: 2,
                type: HotspotAdd,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.tool-setting-hotspot-container {
    height: 90px;
    background: $pano-framework-tool-background;
    display: flex;
    .hotspot-left-container {
        flex: 1;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 10px 0px 10px 10px;
        .hotspot-left-inner-container {
            display: flex;
            height: 70px;
            .hotspot-icon-item-container {
                margin-right: 10px;
                min-width: 20vw;
                max-width: 20vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: $pano-framework-tool-item-background;
                border-radius: 4px;
                box-sizing: border-box;
                .hotspot-icon-image {
                    width: 40px;
                }
                span {
                    width: 20vw;
                    margin-top: 5px;
                    font-size: 12px;
                    text-align: center;
                }
            }
            .hotspot-icon-item-container-active {
                border: solid 2px $pano-theme;
            }
        }
    }
    .hotspot-left-container::-webkit-scrollbar {
        display: none;
    }
    .hotspot-right-container {
        width: 20%;
        box-shadow: -6px 2px 9px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .iconfont {
            font-size: 40px;
        }
    }
}
</style>