<template>
  <div class="camera-cointainer">
      <div class="camera-top"></div>
      <div class="camera-right"></div>
      <div class="camera-bottom"></div>
      <div class="camera-left"></div>
      <div class="camera-left-top"></div>
      <div class="camera-top-left"></div>
      <div class="camera-top-right"></div>
      <div class="camera-right-top"></div>
      <div class="camera-right-bottom"></div>
      <div class="camera-bottom-right"></div>
      <div class="camera-bottom-left"></div>
      <div class="camera-left-bottom"></div>
      <div class="camera-tip-container">
         <span class="camera-tip">拖动全景设置初始视角</span>
      </div>
  </div>
</template>
<script>
export default {
    computed:{

    },
    data(){
        return {
            src1:''
        }
    },
    methods:{
        setDefaultView(){
        }
    }
};
</script>
<style lang="scss" scoped>
.camera-cointainer {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  .camera-tip-container{
      position: absolute;
      bottom: 45%;
      width: 60vw;
      left: -30vw;
      text-align: center;
      .camera-tip{
          font-size: 12px;
          padding: 8px 8px;
          border-radius: 4px;
      background: rgba($pano-framework-tool-background,.3);
      }
  }
  .camera-top{
      position: absolute;
      top: 15%;
      width: 80vw;
      height: 2px;
      left: -40vw;
      border-bottom: 1px dotted #ffffff;
  }
  .camera-right{
      position: absolute;
      top: 15%;
      width: 2px;
      height: 45%;
      left: 40vw;
      border-right: 1px dotted #ffffff;
  }
  .camera-bottom{
      position: absolute;
      bottom: 40%;
      width: 80vw;
      height: 2px;
      left: -40vw;
      border-top: 1px dotted #ffffff;
  }
  .camera-left{
      position: absolute;
      top: 15%;
      width: 2;
      height: 45%;
      left: -40vw;
      border-left: 1px dotted #ffffff;
  }
  .camera-left-top{
      position: absolute;
      width: 5px;
      height: 60px;
      top: 15%;
      left: calc(-40vw - 2.5px);
      background: #ffffff;
  }
  .camera-top-left{
      position: absolute;
      width: 80px;
      height: 5px;
      top: 15%;
      left: -40vw;
      background: #ffffff;
  }
  .camera-top-right{
      position: absolute;
      width: 80px;
      height: 5px;
      top: 15%;
      left: calc(40vw - 80px);
      background: #ffffff;
  }
  .camera-right-top{
      position: absolute;
      width: 5px;
      height: 60px;
      top: 15%;
      left: calc(40vw);;
      background: #ffffff;
  }
  .camera-right-bottom{
      position: absolute;
      width: 5px;
      height: 60px;
      bottom: 40%;
      left: calc(40vw);;
      background: #ffffff;
  }
  .camera-bottom-right{ 
      position: absolute;
      width: 80px;
      height: 5px;
      bottom: 40%;
      left: calc(40vw - 80px);
      background: #ffffff;
  }
  .camera-bottom-left{
      position: absolute;
      width: 80px;
      height: 5px;
      bottom: 40%;
      left: -40vw;
      background: #ffffff;
  }
  .camera-left-bottom{
      position: absolute;
      width: 5px;
      height: 60px;
      bottom: 40%;
      left: calc(-40vw  - 2.5px);
      background: #ffffff;
  }
}
</style>