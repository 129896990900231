<template>
    <div class="tool-hotpsot-container">
        <div class="tool-hotpsot-item-contaienr" @click="hotspotGoScene">
            <i class="iconfont icon-local"></i>
            <span>跳转</span>
        </div>
        <div class="tool-hotpsot-item-contaienr" @click="onHotspotEditHandle">
            <i class="iconfont icon-edit_small"></i>
            <span>编辑</span>
        </div>
        <div class="tool-hotpsot-item-contaienr" @click="onHotspotSceneChangeEditHandle">
            <i class="iconfont icon-view"></i>
            <span>场景</span>
        </div>
        <div class="tool-hotpsot-item-contaienr" @click="onHotspotDelHandle">

            <i class="iconfont icon-shanchu"></i>
            <span>删除</span>
        </div>
    </div>
</template>
<script>
import HotspotSceneEdit from "../nav-bottom-slide/components/hotspot-add/components/hotspot-scene-edit";
import HotspotSceneChangeEdit from "../nav-bottom-slide/components/hotspot-add/components/hotspot-scene-change-edit";
import Confrim from "@/components/comfirm";
import { mapActions, mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("webappEditModule", ["currentHotspot"]),
    },
    methods: {
        ...mapActions("webappEditModule", [
            "onSlideClickHandle",
            "onHotspotRemove",
            "onSceneSelect"
        ]),
        onHotspotEditHandle() {
            this.onSlideClickHandle({
                openType: 2,
                type: HotspotSceneEdit,
            });
        },
        hotspotGoScene() {
            let sceneId = this.currentHotspot.sceneId
            let toSceneId = this.currentHotspot.toSceneId
            // let hotspotId = this.currentHotspot.id
            // this.onHotspotGoScene({
            //     sceneId: sceneId,
            //     toSceneId: toSceneId,
            //     hotspotId: hotspotId,
            // })
            this.$nextTick(() => {
                this.onSceneSelect(toSceneId)
            })
        },
        onHotspotSceneChangeEditHandle() {
            this.onSlideClickHandle({
                openType: 2,
                type: HotspotSceneChangeEdit,
            });
        },
        onHotspotDelHandle() {
            this.$modal({
                template: Confrim,
                params: {
                    title: "提示!",
                    content: "您确定要删除对应的热点吗?",
                },
                close: (o) => {
                    if (o) {
                        console.log(o);
                        this.onHotspotRemove(this.currentHotspot);
                    }
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.tool-hotpsot-container {
    position: absolute;
    top: 20vh;
    right: 0;
    background: rgba($pano-framework-tool-background, 0.5);
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    .tool-hotpsot-item-contaienr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 12px;

        .iconfont {
            font-size: 25px;
            margin-bottom: 5px;
        }
    }
}
</style>