<template>
    <div class="tool-scene-container">
        <div class="scene-item-container" :class="{'scene-item-container-active':currentSceneId==item.id}" v-for="item in scenes" :key="item.id"
        @click="onSecneSelect(item)"
        >
            <img class="scene-thumb" :src="item.cover" alt="">
            <div class="scene-name ellipsis">{{item.name}}</div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
    computed:{
        ...mapState('webappEditModule',['scenes','currentSceneId'])
    },
    methods:{
        ...mapActions('webappEditModule',['onSceneSelect']),
        onSecneSelect(scene){
            if(this.currentSceneId!=scene.id){
                this.onSceneSelect(scene.id)
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.tool-scene-container {
    display: flex;
    padding: 10 10px 10px 20px;
    overflow-x: scroll;
    .scene-item-container {
        border-radius: 6px;
        overflow: hidden;
        border: solid 2px transparent;
        position: relative;
        min-width: calc(18vw);
        margin-right: 10px;
        .scene-thumb {
            min-width: 18vw;
            height: 70px;
        }
        .scene-name{
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 4px 4px;
            text-align: center;
            background: rgba($pano-framework-tool-background,.6);
        }
    }
    .scene-item-container-active{
        border: solid 2px $pano-theme;
    }
}
.tool-scene-container::-webkit-scrollbar {
    display: none;
}
</style>