<template>
    <div class="tool-setting-base-container noscroll">
        <div class="tool-setting-base-main-container">
            <div class="tool-setting-base-item-container" v-for="(item,index) in baseMenus" :key="index" @click="onMenuClickHandle(item)">
                <i class="iconfont" :class="[item.icon]"></i>
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("webappEditModule", ["baseMenus"]),
    },
    methods: {
        ...mapActions("webappEditModule", ["onSlideClickHandle"]),
        onMenuClickHandle(item) {
            if (item.props) {
                this.onSlideClickHandle({
                    openType: item.openType,
                    type: item.props,
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.tool-setting-base-container {
    background: $pano-framework-tool-background;
    height: 90px;
    overflow-x: scroll;
    .tool-setting-base-main-container {
        display: flex;
        padding: 10px 10px 10px 20px;
        height: 100%;
        box-sizing: border-box;
        .tool-setting-base-item-container {
            margin-right: 10px;
            min-width: 18vw;
            background: $pano-framework-tool-item-background;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            .iconfont {
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 4px;
            }
        }
    }
}
</style>