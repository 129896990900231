<template>
    <div class="webapp-pano-center">
        <div id="webapp-pano" class="webapp-pano-view-container" style="height: calc(100vh - 180px);width:100vw;">
            <noscript>
                <table style="width:100%;height: 100%;">
                    <tr style="vertical-align:middle;">
                        <td>
                            <div style="text-align:center;">ERROR:<br /><br />Javascript not activated<br /><br /></div>
                        </td>
                    </tr>
                </table>
            </noscript>
        </div>
        <div class="webapp-pano-tool-container">
            <div class="webapp-pano-tool-scene-container">
                <ToolScene></ToolScene>
            </div>
            <div class="webapp-pano-tool-setting-container">
                <ToolSettingBase v-if="currentIsBaseMainMenu"></ToolSettingBase>
                <ToolSettingFov v-if="currentIsFovMainMenu"></ToolSettingFov>
                <ToolSettingHotspot v-if="currentIsHotspotMainMenu"></ToolSettingHotspot>
            </div>
            <div class="webapp-pano-tool-main-container">
                <ToolMain></ToolMain>
            </div>
        </div>
        <Camera v-if="currentIsFovMainMenu"></Camera>
        <ToolNav v-if="currentIsBaseMainMenu"></ToolNav>
        <SceneAdd v-if="currentIsBaseMainMenu"></SceneAdd>
        <ToolHotspot v-if="noLayoutOn&&currentHotspotId&&currentIsHotspotMainMenu&&currentSceneId==currentHotspotSceneId"></ToolHotspot>
        <PanoTitle></PanoTitle>

        <transition name="slide-right">
            <NavRightSlide v-if="showRightSlide"></NavRightSlide>
        </transition>
        <transition name="slide-bottom">
            <NavBottomSlide v-if="showBottomSlide"></NavBottomSlide>
        </transition>
    </div>
</template>
<script>
import { myPanoProject } from "api/pano/pano";
import ToolHotspot from "./components/tool-hotspot";
import NavRightSlide from "./components/nav-right-slide";
import NavBottomSlide from "./components/nav-bottom-slide";
import PanoTitle from "./components/pano-title";
import Camera from "./components/camera";
import SceneAdd from "./components/scene-add";
import ToolScene from "./components/tool-scene";
import ToolNav from "./components/tool-nav";
import ToolMain from "./components/tool-main";
import ToolSettingBase from "./components/tool-setting/tool-setting-base";
import ToolSettingFov from "./components/tool-setting/tool-setting-fov";
import ToolSettingHotspot from "./components/tool-setting/tool-setting-hotspot";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    mounted() {
        window.updateHotspotData = this.updateHotspotData;
        window.hotspotClickFocus = this.hotspotClickFocus;
        myPanoProject(this.$route.params.id).then((o) => {
            this.project={
                cover:o.cover,
                name:o.name,
                id:o.id,
                description:o.description,
                xml:o.xml
            }
            this.projectScenes=[...o.scenes];
            let settings = {};
            settings["onstart"] = `loadxml('${o.xml}')`;
            embedpano({
                xml: "",
                target: "webapp-pano",
                html5: "auto",
                mobilescale: 1.0,
                passQueryParameters: true,
                vars: settings,
                onready: this.onready,
            });
        });
    },
    computed: {
        ...mapGetters("webappEditModule", [
            "currentIsBaseMainMenu",
            "currentIsFovMainMenu",
            "currentIsHotspotMainMenu",
            "noLayoutOn",
            "currentScene",
            "currentHotspotSceneId",
        ]),
        ...mapState("webappEditModule", [
            "rightSlide",
            "bottomSlide",
            "currentHotspotId",
            "currentSceneId",
            "scenes",
        ]),
        showRightSlide() {
            return this.rightSlide != null;
        },
        showBottomSlide() {
            return this.bottomSlide != null;
        },
    },
    data(){
return {
    project:{},
    projectScenes:[]
}
    },
    methods: {
        ...mapActions("webappEditModule", [
            "initKrpano",
            "onMainMenuClickHandle",
            "onHotspotClickFocus",
            "onHotspotModify",
        ]),
        onready(kp) {
            //             <action name="variables">
            // set(var1,'var1');
            // set(var2,'var2');
            // set(var3,'var4');
            // </action>
            this.initKrpano({
                kp:kp,
                project:this.project,
                scenes:this.projectScenes
            });
        },
        updateHotspotData(sceneId, hsId, ath, atv, hotspotType) {
            if (this.currentScene.id === sceneId) {
                let index = this.currentScene.hotspots.findIndex(
                    (o) => o.id == hsId
                );
                if (index >= 0) {
                    let hotspot = this.currentScene.hotspots[index];
                    let modifyHotspot = Object.assign(hotspot, {});
                    modifyHotspot.ath = ath;
                    modifyHotspot.atv = atv;
                    this.onHotspotModify(modifyHotspot);
                }
            }
            // console.log("updateHotspotData.sceneId:" + sceneId);
            // console.log("updateHotspotData.hsId:" + hsId);
            // console.log("updateHotspotData.ath:" + ath);
            // console.log("updateHotspotData.atv:" + atv);
            // console.log("updateHotspotData.hotspotType:" + hotspotType);
        },
        hotspotClickFocus(sceneId, hsId) {
            //判断当前没有页面打开
            if (this.noLayoutOn) {
                //底部菜单切换到热点
                this.onMainMenuClickHandle(2);
                this.onHotspotClickFocus(hsId);
            }
        },
    },
    beforeDestroy() {
        window.hotspotClickFocus = null;
    },
    components: {
        ToolMain,
        ToolSettingBase,
        ToolSettingFov,
        Camera,
        ToolSettingHotspot,
        ToolNav,
        SceneAdd,
        PanoTitle,
        NavRightSlide,
        ToolScene,
        NavBottomSlide,
        ToolHotspot,
    },
};

// ,
//   mounted () {
//     window.updateHotspotData = this.updateHotspotData
//   },
//   beforeDestroy () {
//     window.updateHotspotData = null;
//   },
//   methods: {
//     updateHotspotData (sceneId, hsId, ath, atv, hotspotType) {
//       console.log('updateHotspotData.sceneId:'+sceneId);
//       console.log('updateHotspotData.hsId:'+hsId);
//       console.log('updateHotspotData.ath:'+ath);
//       console.log('updateHotspotData.atv:'+atv);
//       console.log('updateHotspotData.hotspotType:'+hotspotType);
//     }
//   }
</script>
<style lang="scss" scoped>
.webapp-pano-center {
    height: 100vh;
    color: $pano-font-color;
    .webapp-pano-view-container {
        height: calc(100vh - 180px);
        width: 100vw;
    }
    .webapp-pano-tool-container {
        background: $pano-framework-background;
        height: 180px;
        min-height: 180px;
        position: relative;
        .webapp-pano-tool-scene-container {
            height: 90px;
            background: linear-gradient(
                to bottom,
                rgba($pano-framework-tool-background, 0.3),
                rgba($pano-framework-tool-background, 0.8)
            );
            position: absolute;
            width: 100%;
            top: -90px;
        }
        .webapp-pano-tool-setting-container {
            height: 90px;
        }
        .webapp-pano-tool-main-container {
            height: 90px;
        }
    }
}
</style>