<template>
    <div class="tool-nav-contaienr">
        <div class="tool-nav-item-contaienr">
            <i class="iconfont icon-edit_small"></i>
            <span>改名称</span>
        </div>
        <div class="tool-nav-item-contaienr">

            <i class="iconfont icon-shanchu"></i>
            <span>删照片</span>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.tool-nav-contaienr {
    position: absolute;
    top: 20vh;
    right: 0;
    background: rgba($pano-framework-tool-background, 0.5);
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    .tool-nav-item-contaienr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 12px;
        .iconfont{
            font-size: 25px;
            margin-bottom: 5px;
        }
    }
}
</style>