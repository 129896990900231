<template>
    <div class="hotspot-scene-change-edit-container">
        <div class="hotspot-scene-preview-container">
            <div class="hotspot-scene-title-container">
                拖动全景图设置进入视角
            </div>
            <div class="hotspot-scene-preview-inner-container">
                <div class="webapp-inner-pano-view-wrapper">
                <div id="webapp-inner-pano-edit" class="webapp-inner-pano-view-container">
                    <noscript>
                        <table style="width:100%;height:100%;">
                            <tr style="vertical-align:middle;">
                                <td>
                                    <div style="text-align:center;">ERROR:<br /><br />Javascript not activated<br /><br /></div>
                                </td>
                            </tr>
                        </table>
                    </noscript>
                </div>
                </div>
                <Camera2></Camera2>
            </div>
        </div>
        <div class="hotspot-scene-main-container">
            <!-- 导航栏 -->
            <div class="title-container">
                <div class="title-close" @click="onClose">
                    <i class="iconfont icon-close"></i>
                </div>
                <div class="title">选择跳转场景</div>
                <div class="title-next">
                </div>
            </div>

            <!-- 选择场景 -->
            <div class="hotspot-select-scene-container noscroll">
                <div class="hotspot-select-scene-item-container" :class="{'hotspot-select-scene-item-container-active':sceneId==scene.id}" v-for="scene in scenes" :key="scene.id" @click="onSceneClickHandle(scene.id)">
                    <img class="hotspot-select-scene-image" :src="scene.cover" alt="">
                    <span class="hotspot-select-scene-name ellipsis">{{scene.name}}</span>
                </div>
            </div>
            <div class="hotspot-select-scene-confirm-container">
                <div class="select-scene-confirm-button" @click="onConfirm">确定</div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    hiddenTool,
    loadScene,
    getSceneScreenCenter,
    loadSceneWithParams
} from "@/utils/krpanoUtil";
import { mapActions, mapState,mapGetters  } from "vuex";
import Camera2 from "../../../../../camera2";
export default {
    components: {
        Camera2,
    },
    mounted() {
        this.hotspot = { ...this.currentHotspot };
        if (!this.hotspot.toSceneId&&this.scenes.length > 0) {
            this.sceneId = this.scenes[0].id;
        }else{
            this.sceneId = this.hotspot.toSceneId;
        }
        let settings = {};
        settings["onstart"] = `loadxml('${this.xml}')`;
        embedpano({
            xml: "",
            target: "webapp-inner-pano-edit",
            html5: "auto",
            mobilescale: 1.0,
            passQueryParameters: true,
            vars: settings,
            onready: this.onready,
        });
    },
    computed: {
        ...mapGetters("webappEditModule", ["currentHotspot"]),
        ...mapState("webappEditModule", ["scenes","xml"]),
    },
    data() {
        return {
            krpano: null,
            sceneId: null,
            hotspot: {
                id: null,
                sceneId: null,
                name: "未命名",
                type: null,
                iconId: null,
                icon: null,
                size: 50,
                fontSize: 12,
                toSceneId: null,
                toSceneAtvLookat: 0,
                toSceneAthLookat: 0,
            },
        };
    },
    methods: {
        ...mapActions("webappEditModule", ["onSlideClose", "onHotspotIconModify","onHotspotModify"]),
        onClose() {
            this.onSlideClose();
        },
        onSceneClickHandle(sceneId) {
            if (this.sceneId != sceneId) {
                this.sceneId = sceneId;
                loadScene(this.krpano, this.sceneId);
                // loadSceneWithParams(this.krpano, this.sceneId,'view.vlookat=22&view.hlookat=33');
            }
        },
        onready(kp) {
            this.krpano = kp;
            setTimeout(() => {
                hiddenTool(this.krpano);
            }, 400);
        },
        onConfirm() {
            let r = getSceneScreenCenter(this.krpano, "#webapp-inner-pano-edit");
            this.hotspot.toSceneId=this.sceneId;
            this.hotspot.toSceneAtvLookat=r.atv;
            this.hotspot.toSceneAthLookat=r.ath;
            this.onHotspotModify(this.hotspot);
            this.onSlideClose();
        },
    },
};
</script>
<style lang="scss" scoped>
.hotspot-scene-change-edit-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .hotspot-scene-preview-container {
        flex: 1;
        background: rgba($pano-framework-tool-background, 0.6);
        display: flex;
        flex-direction: column;
        .hotspot-scene-title-container {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
                to bottom,
                rgba($pano-framework-tool-background, 0.6),
                rgba($pano-framework-tool-background, 0.1)
            );
        }
        .hotspot-scene-preview-inner-container {
            flex: 1;
            margin: 20px 60px 60px 60px;
            position: relative;
            .webapp-inner-pano-view-wrapper {
                height: calc(50vh);
                width: calc(100vw - 120px);
                border-radius: 20px;
                overflow: hidden;
                .webapp-inner-pano-view-container {
                    height: calc(50vh);
                    width: calc(100vw - 120px);
                }
            }
        }
    }
    .hotspot-scene-main-container {
        background: $pano-framework-tool-background;
        height: 30vh;
        display: flex;
        flex-direction: column;

        .title-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            margin-bottom: 10px;
            .title-close,
            .title-next {
                padding: 0 20px;
                .iconfont {
                    font-size: 24px;
                }
            }
            .title {
                font-size: 18px;
                color: #9499a6;
            }
        }

        .hotspot-select-scene-container {
            display: flex;
            padding: 0 10px 0 20px;
            overflow-x: scroll;
            .hotspot-select-scene-item-container {
                background: $pano-framework-tool-item-background;
                margin-right: 10px;
                height: 14vw;
                width: 20vw;
                min-width: 20vw;
                overflow: hidden;
                border-radius: 6px;
                border: 2px solid transparent;
                box-sizing: border-box;
                position: relative;
                .hotspot-select-scene-image {
                    height: 14vw;
                    width: 20vw;
                }
                .hotspot-select-scene-name {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 4px 4px;
                    text-align: center;
                    background: rgba($pano-framework-tool-background, 0.6);
                }
            }
            .hotspot-select-scene-item-container-active {
                border: 2px solid $pano-theme;
            }
        }
        .hotspot-select-scene-confirm-container {
            flex: 1;
            display: flex;
            justify-content: center;
            padding: 20px;
            .select-scene-confirm-button {
                background: $pano-theme;
                height: 40px;
                width: 160px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>