<template>
    <div class="nav-bottom-slide-container">
        <component :is="(bottomSlide || {}).type"></component>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('webappEditModule',['bottomSlide'])
    }
    
}
</script>
<style lang="scss" scoped>
    .nav-bottom-slide-container{
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>