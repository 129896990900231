<template>
    <div class="pano-title-container">
        <div class="nav-back" :class="{'nav-disabled':!noLayoutOn}" @click="back">
            <i class="iconfont icon-tuichu"></i>
            退出
        </div>
        <div class="nav-pub" :class="{'nav-disabled':!noLayoutOn}" @click="onPublish">
            <i class="iconfont icon-fabusekuai"></i>
            预览/发布
        </div>
    </div>
</template>
<script>
import ProjectInfo from '../nav-right-slide/components/project-info'
import { mapGetters,mapActions } from 'vuex';
export default {
    computed:{
        ...mapGetters('webappEditModule',['noLayoutOn'])
    },
    methods:{
        ...mapActions("webappEditModule", ["onSlideClickHandle"]),
        back(){
            window.location=window.origin
        },
        onPublish(){
            this.onSlideClickHandle({
                    openType: 1,
                    type: ProjectInfo,
                })
        }
    }
};
</script>
<style lang="scss" scoped>
.pano-title-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    background: rgba($pano-framework-tool-background, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-back,
    .nav-pub {
        display: flex;
        padding: 0px 20px;
        .iconfont {
            margin-right: 4px;
        }
    }
    .nav-disabled{
        color: #999999;
    }
}
</style>