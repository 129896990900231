<template>
    <div class="hotspot-add-container">
        <HotspotTypeSelect v-if="steps==0" @onStep="modifyStep" @onHotspotCreate="modifyHotspot"></HotspotTypeSelect>
        <HotspotSceneStepTwo v-if="steps==1&&(hotspot.type==1 || hotspot.type==4 || hotspot.type==5)" :hs="hotspot" @onStep="modifyStep" @onHotspotModify="modifyHotspot"></HotspotSceneStepTwo>
        <HotspotSceneStepThree v-if="steps==2&&hotspot.type==1" @onStep="modifyStep" :hs="hotspot" @onHotspotModify="modifyHotspot"></HotspotSceneStepThree>
        <HotspotSceneStepVideo v-if="steps==2&&hotspot.type==4" @onStep="modifyStep" :hs="hotspot" @onHotspotModify="modifyHotspot"></HotspotSceneStepVideo>
        <HotspotSceneStepText v-if="steps==2&&hotspot.type==5" @onStep="modifyStep" :hs="hotspot" @onHotspotModify="modifyHotspot"></HotspotSceneStepText>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import HotspotTypeSelect from "./components/hotspot-type-select";
import HotspotSceneStepTwo from "./components/hotspot-scene-step-two";
import HotspotSceneStepThree from "./components/hotspot-scene-step-three";
import HotspotSceneStepVideo from "./components/hotspot-scene-step-video";
import HotspotSceneStepText from "./components/hotspot-scene-step-text";
export default {
    components: {
        HotspotTypeSelect,
        HotspotSceneStepTwo,
        HotspotSceneStepThree,
        HotspotSceneStepVideo,
        HotspotSceneStepText
    },
    computed:{

...mapState("webappEditModule",["hotspotIcons"])
    },
    data() {
        return {
            steps: 0,
            hotspot: {
                id: null,
                sceneId: null,
                name: "未命名",
                type: null,
                iconId: null,
                icon: null,
                size: 50,
                fontSize: 12,
                toSceneId: null,
                toSceneAtvLookat: 0,
                toSceneAthLookat: 0,
                videoUrl:null
            },
        };
    },
    methods: {
        ...mapActions("webappEditModule", ["onHotspotIconModify","onHotspotModify"]),
        modifyStep(step) {
            this.steps = step;
        },
        modifyHotspot(hotspot) {
            this.hotspot = { ...hotspot };
            this.onHotspotModify(this.hotspot);
        }
    },
};
</script>
<style lang="scss" scoped>
.hotspot-add-container {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    ::v-deep .pano-input {
        background: $pano-framework-tool-item-background;
        color: $pano-font-color;
    }
    ::v-deep .pano-input:hover {
        border-color: $pano-theme;
    }
}
</style>