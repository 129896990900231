<template>
    <div class="scene-add-container">
        <div class="scene-add-button-container">
            <i class="iconfont icon-hao"></i>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.scene-add-container {
    position: absolute;
    bottom: 300px;
    right: 0;
    padding: 10px;
    padding-right: 0;
    background: rgba($pano-framework-tool-background, 0.5);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    .scene-add-button-container {
        padding: 0px 15px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $pano-theme;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
}
</style>