<template>
  <div class="hotspot-scene-step-text-container">
    <div class="hotspot-scene-text-hold-container"></div>
    <div class="hotspot-scene-main-container">
      <!-- 导航栏 -->
      <div class="title-container">
        <div class="title-close" @click="onClose">
          <i class="iconfont icon-close"></i>
        </div>
        <div class="title">输入视频播放链接</div>
        <div class="title-next" @click="onPreview">
          上一步
        </div>
      </div>

      <!-- 输入链接 -->
      <div class="hotspot-text-url-input-container noscroll">
        <!-- <pano-input
          placeholder="输入视频播放链接"
          v-model="content"
        ></pano-input> -->
        <wangEditPage
          :value="content"
          ref="wangEdit"
          style="height:calc(80vh - 120px)"
        ></wangEditPage>
      </div>
      <div class="hotspot-select-scene-confirm-container">
        <div class="select-scene-confirm-button" @click="onConfirm">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import PanoInput from '@/components/pano-input/index.vue'
import hotspot from '@/views/edit/store/hotspot'
import wangEditPage from './wangEdit.vue'
export default {
  components: {
    PanoInput,
    wangEditPage,
  },
  props: {
    hs: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.hotspot = { ...this.hs }
  },
  computed: {
    ...mapState('webappEditModule', ['scenes', 'xml']),
  },
  data() {
    return {
      krpano: null,
      sceneId: null,
      content: '',
      hotspot: {
        id: null,
        sceneId: null,
        name: '未命名',
        type: null,
        iconId: null,
        icon: null,
        size: 50,
        fontSize: 12,
        toSceneId: null,
        toSceneAtvLookat: 0,
        toSceneAthLookat: 0,
        videoUrl: null,
        content: null,
      },
    }
  },
  methods: {
    ...mapActions('webappEditModule', [
      'onSlideClose',
      'onHotspotIconModify',
      'onHotspotRemove',
    ]),
    onClose() {
      this.onHotspotRemove(this.hotspot)
      this.onSlideClose()
    },
    onPreview() {
      this.$emit('onStep', 1)
    },
    onConfirm() {
      var content = this.$refs.wangEdit.getContent()
      if (!content) {
        this.$alert({ content: '请输入视频地址' })
        return
      }
      this.hotspot.content = content
      console.log(this.hotspot)
      this.onSlideClose()
      this.$emit('onHotspotModify', this.hotspot)
    },
  },
}
</script>
<style lang="scss" scoped>
.hotspot-scene-step-text-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .hotspot-scene-text-hold-container {
    flex: 1;
    flex-direction: column;
  }
  .hotspot-scene-main-container {
    background: $pano-framework-tool-background;
    height: 80vh;
    display: flex;
    flex-direction: column;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      margin-bottom: 10px;
      .title-close,
      .title-next {
        padding: 0 20px;
        .iconfont {
          font-size: 24px;
        }
      }
      .title {
        font-size: 18px;
        color: #9499a6;
      }
    }

    .hotspot-text-url-input-container {
      display: flex;
      padding: 0 10px 0 20px;
      overflow-x: scroll;
    }
    .hotspot-select-scene-confirm-container {
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 20px;
      .select-scene-confirm-button {
        background: $pano-theme;
        height: 40px;
        width: 160px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
