<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden;"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { uploadOss } from 'api/common/common'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  model: {
    event: 'change',
  },
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            customUpload(file, insertFn) {
              console.log(file)
              var formData = new FormData()
              formData.append('file', file)
              uploadOss(formData).then((res) => {
                if (res.success) {
                  insertFn(res.url, '暂未加载图片', res.url)
                }
              })
            },
          },
        },
      },
      mode: 'simple', // or 'simple'
    }
  },
  methods: {
    getContent() {
      return this.html
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
