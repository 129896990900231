<template>
    <div class="nav-right-slide-container">
        <component :is="(rightSlide || {}).type"></component>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('webappEditModule',['rightSlide'])
    }
    
}
</script>
<style lang="scss" scoped>
    .nav-right-slide-container{
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $pano-framework-background;
    }
</style>