<template>
    <div class="hotspot-scene-step-two-container">
        <!-- 导航栏 -->
        <div class="title-container">
            <div class="title-close" @click="onClose">
                <i class="iconfont icon-close"></i>
            </div>
            <div class="title">选择热点图标</div>
            <div class="title-next" @click="onNext">
                下一步
            </div>
        </div>
        <div class="hotspot-pano-input-container">
            <pano-input placeholder="请输入热点标题" v-model="hotspot.name"></pano-input>
        </div>
        <!-- 选择热点图标 -->
        <div class="hotspot-select-icon-container noscroll">
            <div class="hotspot-select-icon-inner-container">

                <div class="hotspot-select-icon-item-container" v-for="item in hotspotIcons" :key="item.id" @click="onHotspotSceneSelectIcon(item)">
                    <div class="hotspot-select-icon-item-inner-container" :class="{'hotspot-select-icon-item-inner-container-active':hotspot.iconId===item.id}">
                        <img class="hotspot-select-icon-image" :src="item.iconThumb" alt="">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    props: {
        hs: {
            type: Object,
            default: {},
        },
    },
    mounted() {
        this.hotspot = { ...this.hs };
    },
    watch: {
        hotspotName() {
            this.onHotspotNameModify(this.hotspot);
        }
    },
    computed: {
        ...mapState("webappEditModule",["hotspotIcons"]),
        hotspotName() {
            return this.hotspot.name;
        },
    },
    data() {
        return {
            hotspot: {
                id: null,
                sceneId: null,
                name: "未命名",
                type: null,
                iconId: null,
                icon: null,
                size: 50,
                fontSize: 12,
                toSceneId: null,
                toSceneAtvLookat: 0,
                toSceneAthLookat: 0,
            },
        };
    },
    methods: {
        ...mapActions("webappEditModule", [
            "onSlideClose",
            "onHotspotIconModify",
            "onHotspotNameModify",
            "onHotspotRemove"
        ]),
        onClose() {
            this.onHotspotRemove(this.hotspot);
            this.onSlideClose();
        },
        onNext() {
            this.$emit("onStep", 2);
            this.$emit("onHotspotModify", this.hotspot);
        },
        onHotspotSceneSelectIcon(icon) {
            if (this.hotspot.iconId != icon.id) {
                this.hotspot.iconId = icon.id;
                this.hotspot.icon = icon.icon;
                this.hotspot.iconThumb = icon.iconThumb;
                this.onHotspotIconModify(this.hotspot);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.hotspot-scene-step-two-container {
    background: $pano-framework-tool-background;
    flex: 1;
    padding-bottom: 10px;
    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        .title-close,
        .title-next {
            padding: 0 20px;
            .iconfont {
                font-size: 24px;
            }
        }
        .title {
            font-size: 18px;
            color: #9499a6;
        }
    }
    .hotspot-pano-input-container {
        padding: 10px 80px;
    }

    .hotspot-select-icon-container {
        height: 25vh;
        padding: 0 10px;
        overflow-y: scroll;
        .hotspot-select-icon-inner-container {
            display: flex;
            flex-wrap: wrap;
            .hotspot-select-icon-item-container {
                width: calc((100vw - 20px) / 5);
                height: calc((100vw - 20px) / 5);
                padding: 6px;
                box-sizing: border-box;
                .hotspot-select-icon-item-inner-container {
                    background: $pano-framework-tool-item-background;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 6px;
                    border: 2px solid transparent;
                    box-sizing: border-box;
                    .hotspot-select-icon-image {
                        height: 100%;
                        width: 100%;
                    }
                }
                .hotspot-select-icon-item-inner-container-active {
                    border: 2px solid $pano-theme;
                }
            }
        }
    }
}
</style>