<template>
    <div class="hotspot-select-type-container">
        <!-- 导航栏 -->
        <div class="title-container">
            <div class="title-close" @click="onClose">
                <i class="iconfont icon-close"></i>
            </div>
            <div class="title">选择热点类型</div>
            <div class="title-next">
            </div>
        </div>
        <!-- 热点选择 -->
        <div class="hotspot-select-container">
            <div class="hotspot-item-container" v-for="item in hotspots" :key="item.hotspotType" @click="onHotspotSelect(item.hotspotType)">
                <div class="hotspot-item-icon-container">
                    <i class="iconfont" :class="[item.icon]"></i>
                </div>
                <div>{{item.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/common/js/util";
import { mapActions,mapState } from "vuex";
export default {
    computed: {
        ...mapState("webappEditModule", ["currentSceneId","hotspotIcons"]),
    },
    data() {
        return {
            hotspot: {
                id: null,
                sceneId: null,
                name: "未命名",
                type: null,
                iconId: null,
                icon: null,
                size: 50,
                fontSize: 12,
                toSceneId: null,
                toSceneAtvLookat: 0,
                toSceneAthLookat: 0,
                videoUrl:null
            },
            hotspots: [
                {
                    hotspotType: 1,
                    icon: "icon-nintendogamecube",
                    name: "场景导航",
                },
                {
                    hotspotType: 2,
                    icon: "icon-tupian",
                    name: "空间广告",
                },
                {
                    hotspotType: 3,
                    icon: "icon-guanggaoguanli",
                    name: "墙体广告",
                },
                {
                    hotspotType: 4,
                    icon: "icon-icon_play",
                    name: "视频",
                },
                {
                    hotspotType: 5,
                    icon: "icon-wenzi",
                    name: "文字",
                },
                {
                    hotspotType: 6,
                    icon: "icon-music",
                    name: "语音",
                },
            ],
        };
    },
    methods: {
        ...mapActions("webappEditModule", [
            "onSlideClose",
            "onHotspotAdd",
            "onHotspotIconModify",
            "onHotspotNameModify"
        ]),
        onClose() {
            this.onSlideClose();
        },
        onHotspotSelect(hotspotType) {
            if(hotspotType!=1&&hotspotType!=4&&hotspotType!=5){
                this.$alert({content:'暂时未开通该功能'})
                return;
            }
            this.hotspot.id = `hotspot_${Util.newGuid(32, 16).toLowerCase()}`;
            this.hotspot.sceneId = this.currentSceneId;
            this.hotspot.type = hotspotType;
            if (hotspotType == 1 || hotspotType == 4 || hotspotType == 5) {
                if(this.hotspotIcons&&this.hotspotIcons.length>0){
                this.hotspot.iconId = this.hotspotIcons[0].id;
                this.hotspot.icon = this.hotspotIcons[0].icon;
                this.hotspot.iconThumb = this.hotspotIcons[0].iconThumb;
                }
            }
            this.doAddHotspot();
            this.$emit("onStep", 1);
            this.$emit("onHotspotCreate", this.hotspot);
        },
        doAddHotspot() {
            this.onHotspotAdd(this.hotspot);
            this.onHotspotNameModify(this.hotspot);
            this.onHotspotIconModify(this.hotspot);
        },
    },
};
</script>
<style lang="scss" scoped>
.hotspot-select-type-container {
    background: $pano-framework-tool-background;
    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        margin-bottom: 20px;
        .title-close,
        .title-next {
            padding: 0 20px;
            .iconfont {
                font-size: 24px;
            }
        }
        .title {
            font-size: 18px;
            color: #9499a6;
        }
    }
    .hotspot-select-container {
        padding: 0 5vw;
        padding-bottom: 10vw;
        display: flex;
        flex-wrap: wrap;
        .hotspot-item-container {
            width: calc(67.5vw / 3);
            margin: 0 3.75vw;
            text-align: center;
            font-size: 16px;
            margin-bottom: 40px;
            .hotspot-item-icon-container {
                background: $pano-framework-tool-item-background;
                height: calc(67.5vw / 3);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                margin-bottom: 20px;
                .iconfont {
                    font-size: 40px;
                }
            }
        }
    }
}
</style>