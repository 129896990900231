<template>
    <div class="camera2-container">
        <div class="camera2-top"></div>
        <div class="camera2-right"></div>
        <div class="camera2-bottom"></div>
        <div class="camera2-left"></div>
        <div class="camera2-top-left"></div>
        <div class="camera2-left-top"></div>
        <div class="camera2-top-right"></div>
        <div class="camera2-right-top"></div>


        
        <div class="camera2-bottom-left"></div>
        <div class="camera2-bottom-right"></div>
        <div class="camera2-left-bottom"></div>
        <div class="camera2-right-bottom"></div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.camera2-container {
    position: absolute;
    top: 5vh;
    left: 10%;
    width: 80%;
    .camera2-top{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #ffffff;
    }
    .camera2-bottom{
        position: absolute;
        top: 40vh;
        left: 0;
        width: 100%;
        height: 2px;
        background: #ffffff;

    }
    .camera2-right{
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 40vh;
        background: #ffffff;

    }
    .camera2-left{
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 40vh;
        background: #ffffff;

    }
    .camera2-top-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 6vh;
        height: 6px;
        background: #ffffff;
    }
    .camera2-left-top{
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 6vh;
        background: #ffffff;
    }
    .camera2-top-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 6vh;
        height: 6px;
        background: #ffffff;
    }
    .camera2-right-top{
        position: absolute;
        top: 0;
        right: 0;
        width: 6px;
        height: 6vh;
        background: #ffffff;
    }



    
    .camera2-bottom-left {
        position: absolute;
        top: calc(40vh - 6px);
        left: 0;
        width: 6vh;
        height: 6px;
        background: #ffffff;
    }
    .camera2-left-bottom{
        position: absolute;
        top: 34vh;
        left: 0;
        width: 6px;
        height: 6vh;
        background: #ffffff;
    }
    .camera2-bottom-right {
        position: absolute;
        top: calc(40vh - 6px);
        right: 0;
        width: 6vh;
        height: 6px;
        background: #ffffff;
    }
    .camera2-right-bottom{
        position: absolute;
        top: 34vh;
        right: 0;
        width: 6px;
        height: 6vh;
        background: #ffffff;
    }
}
</style>